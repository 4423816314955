@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');

body{
    font-family: 'Raleway', sans-serif;
    font-weight:  "600";
    color: rgb(19, 19, 19);
}


:root {
    --org: orangered;
    --org2:rgb(65, 81, 164);
    --gry: rgb(232, 232, 232);
    --dark2: rgb(60, 58, 58);
    --dark: black;
    --grn: rgb(5, 145, 124);
}

.btn_cli {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: none;
    border: 3px solid black;
    border-bottom-color: var(--org);
    border-top-color: var(--gry);
    /* animation-name: animeme;
    animation-duration: 1s linear infinite;
    animation-fill-mode: forwards; */
    animation: 1s animeme linear infinite;
}

@keyframes animeme {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
button * {
    /* width: 100%; */

}


@keyframes loader-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader {
    border-right-color: transparent;
    animation: loader-rotate 1s linear infinite;
}
.topbr{
    background-color: var(--dark2);
    border-bottom: 5px solid var(--gry);
}
.topbr *  {
    /* font-weight: 100; */
}
.sidebr{
    position: relative;
    margin-left: -3px;
    background-color: var(--dark2);
}
.sidebr * {

}
/* */
.m_cards > *  {
    background: linear-gradient(60deg, rgba(0, 255, 42, 0.161) 10%, rgba(0, 0, 255, 0.189) 40%, rgba(0, 255, 42, 0.161)  10%);
}
.m_cards > *:nth-child(2)  {
    background: linear-gradient(60deg, rgba(255, 123, 0, 0.161) 10%, rgba(17, 0, 255, 0.189) 40%, rgba(0, 255, 42, 0.161)  10%);
}
.toggle-checkbox:checked {
    @apply: right-0 border-green-400;
    right: 0;
    border-color: #68D391;
}
.toggle-checkbox:checked + .toggle-label {
    @apply: bg-green-400;
    background-color: #68D391;
}